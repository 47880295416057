import React, { useCallback, useMemo } from 'react'

import { useQuery } from '@apollo/client'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box, CircularProgress, Divider, Typography } from '@mui/material'
import { EmptyChartPlaceholder, LoaderHolder } from 'common'
import {
  BridgedCirculationChart,
  BridgedTokenChart,
  CirculationChart,
  StakingTermsCirculationChart,
  TokenPriceChart,
  TokenStakedBarChart,
  TokenStakedChart,
  UnstakeStatsChart,
  UptimeStatsChart,
  ValidationsChart,
  ValidatorsBarChart,
  VersionsDonutCharts,
} from 'common/blocks'
import ChartContainer from 'common/blocks/chartContainer'
import NodeStakedByUserChart from 'common/blocks/nodeStakedByUserChart'
import { EntityCountByPeriod, Range, StakingTermsLabel } from 'common/types'
import { ChartType } from 'constants/chartOptions'
import {
  SYSTEM_DAILY_STATS_DATASET,
  SYSTEM_DAILY_STATS_GRAPH_DATA,
} from 'graphql/systemDailyStats/queries'
import { DateTime } from 'luxon'
import { getFormattedNumber } from 'utils/Number'
import { calculatePercentage } from 'utils/Percentage'

import isUndefined from 'lodash/isUndefined'

import { DEFAULT_PERIOD_DAYS } from './mnwDailyStats'
import { DiffBlock, NumberStats, Title } from './styles'

import { BorderLinearProgress } from '../rewards/Total/styles'

interface Props {
  range: Range
}

const diffColors = ['#E93940', '#4D75B8', '#26B568']

function StatsGraphContainer({ range }: Props) {
  const { data, loading } = useQuery(SYSTEM_DAILY_STATS_GRAPH_DATA, {
    variables: {
      from: range?.from?.toFormat('yyyy-MM-dd') || undefined,
      to: range?.to?.toFormat('yyyy-MM-dd') || undefined,
    },
  })

  const { data: dataset, loading: datasetLoading } = useQuery(
    SYSTEM_DAILY_STATS_DATASET,
  )

  const systemDailyStatsDataByFields = useMemo(
    () => data?.systemDailyStatsGraphData || {},
    [data],
  )

  const systemDailyStatsDataset = useMemo(
    () => dataset?.systemDailyStatsDataset || {},
    [dataset],
  )

  const getDiffColor = useCallback((diff: number) => {
    if (diff === 0) {
      return diffColors[1]
    }
    if (diff < 0) {
      return diffColors[0]
    }
    return diffColors[2]
  }, [])

  const renderDiffBlock = useCallback(
    (diff: number, percentage?: number, withoutArrow?: boolean) => {
      if (isUndefined(diff)) return null
      return (
        <DiffBlock display="flex" sx={{ color: getDiffColor(diff) }}>
          {
            <div>
              {!withoutArrow && !!diff && (
                <div>
                  {diff > 0 ? (
                    <ArrowUpwardIcon sx={{ mr: '12px' }} />
                  ) : (
                    <ArrowDownwardIcon sx={{ mr: '12px' }} />
                  )}
                </div>
              )}
            </div>
          }
          <Typography mr="12px">{getFormattedNumber(diff)}</Typography>
          <Typography>
            {percentage ? `(${percentage.toFixed(2)}%)` : ''}
          </Typography>
        </DiffBlock>
      )
    },
    [getDiffColor],
  )

  // todo in circulation supply i got total supply
  const totalSupply = useMemo(
    () => systemDailyStatsDataset?.circulationSupply || 0,
    [systemDailyStatsDataset],
  )

  const bridgedTokens = useMemo(
    () => systemDailyStatsDataset?.bridgedTokenAmount || 0,
    [systemDailyStatsDataset],
  )

  const stakedTokens = useMemo(
    () => systemDailyStatsDataset?.stackedTokenAmount || 0,
    [systemDailyStatsDataset],
  )

  const nodesByStakingTermsChartData = useMemo(
    () =>
      systemDailyStatsDataset?.nodesCountByPeriod
        ? [
            ['StakingTerm', 'Nodes'],
            ...systemDailyStatsDataset.nodesCountByPeriod.map(
              (stats: EntityCountByPeriod) => [
                StakingTermsLabel[stats.period],
                stats.count,
              ],
            ),
          ]
        : [],
    [systemDailyStatsDataset],
  )

  const validatorsByStakingTermsChartData = useMemo(
    () =>
      systemDailyStatsDataset?.validatorsCountByPeriod
        ? [
            ['StakingTerm', 'Validators'],
            ...systemDailyStatsDataset.validatorsCountByPeriod.map(
              (stats: EntityCountByPeriod) => [
                StakingTermsLabel[stats.period],
                stats.count,
              ],
            ),
          ]
        : [],
    [systemDailyStatsDataset],
  )

  const tokensCirculationChartData = useMemo(
    () =>
      isUndefined(systemDailyStatsDataset?.circulationSupply) &&
      isUndefined(systemDailyStatsDataset?.stackedTokenAmount)
        ? []
        : [
            ['Total ', 'Staked'],
            ['Total Tokens', systemDailyStatsDataset?.circulationSupply],
            ['Staked Tokens', systemDailyStatsDataset?.stackedTokenAmount],
          ],
    [systemDailyStatsDataset],
  )

  const validatorsCirculationChartData = useMemo(
    () =>
      isUndefined(systemDailyStatsDataset?.totalActiveValidatorsCount) &&
      isUndefined(systemDailyStatsDataset?.totalValidatorsCount)
        ? []
        : [
            ['Total', 'Active'],
            ['Total Validators', systemDailyStatsDataset?.totalValidatorsCount],
            [
              'Active Validators',
              systemDailyStatsDataset?.totalActiveValidatorsCount,
            ],
          ],
    [systemDailyStatsDataset],
  )

  if (loading || datasetLoading)
    return (
      <LoaderHolder>
        <CircularProgress />
      </LoaderHolder>
    )

  return (
    <Box display="grid">
      <Box>
        <Divider />
        <Title my={3}>MNW Bridged</Title>
      </Box>
      <Box
        display="grid"
        gap="24px"
        gridTemplateColumns="2fr 1fr"
        height="544px"
        mb={3}
      >
        <ChartContainer
          info={`The data for past ${DEFAULT_PERIOD_DAYS} days`}
          subtitle="Number of all bridged tokens"
          title="MNW Bridged - Total"
          withoutBottomPadding
        >
          <BridgedTokenChart
            chartData={systemDailyStatsDataByFields?.bridgedTokenAmount}
            diff={systemDailyStatsDataByFields?.bridgedTokenDailyDiff}
          />
        </ChartContainer>

        <Box display="grid" gap="24px" gridTemplateRows="2fr 1fr">
          <ChartContainer
            info={`The data as of ${DateTime.now()
              .minus({ days: 1 })
              .toFormat('MMM dd, yyyy')}`}
            subtitle="Percentage tokens bridged of circulation supply"
            title="MNW Bridged - Circul., %"
          >
            <BridgedCirculationChart
              bridgedTokens={bridgedTokens}
              legendTitles={['Staked', 'Bridged', 'Total Supply']}
              stakedTokens={stakedTokens}
              totalSupply={totalSupply}
            />
          </ChartContainer>

          <ChartContainer
            info={'The comparison with previous month'}
            subtitle="A difference of bridged tokens from the previous period"
            title="MNW Bridged - Change"
          >
            {renderDiffBlock(
              systemDailyStatsDataset?.bridgedTokenDiff ?? 0,
              calculatePercentage(
                systemDailyStatsDataset?.bridgedTokenDiff,
                systemDailyStatsDataset?.bridgedTokenAmount,
              ),
            )}
          </ChartContainer>
        </Box>
      </Box>

      <Box>
        <Divider />
        <Title my={3}>MNW Staked</Title>
      </Box>
      <Box
        display="grid"
        gap="24px"
        gridTemplateColumns="2fr 1fr"
        height="544px"
        mb={3}
      >
        <ChartContainer
          info={`The data for past ${DEFAULT_PERIOD_DAYS} days`}
          subtitle="The number of all tokens staked"
          title="MNW Staked - Total"
          withoutBottomPadding
        >
          <TokenStakedChart
            chartData={systemDailyStatsDataByFields?.stackedTokenAmount}
          />
        </ChartContainer>

        <Box display="grid" gap="24px" gridTemplateRows="2fr 1fr">
          <ChartContainer
            info={`The data as of ${DateTime.now()
              .minus({ days: 1 })
              .toFormat('MMM dd, yyyy')}`}
            subtitle="Percentage tokens staked of circulating supply"
            title="MNW Staked - Circul., %"
          >
            <CirculationChart chartData={tokensCirculationChartData} />
          </ChartContainer>

          <ChartContainer
            info={'The comparison with previous week'}
            subtitle="A difference in the amount of staked tokens weekly"
            title="MNW Staked - Change"
          >
            {renderDiffBlock(
              systemDailyStatsDataset?.tokensWeeklyDiff ?? 0,
              systemDailyStatsDataset?.tokensWeeklyDiffPercentage ?? 0,
            )}
          </ChartContainer>
        </Box>
      </Box>

      <Box>
        <Divider />
        <Title my={3}>Nodes</Title>
      </Box>
      <Box
        display="grid"
        gap="24px"
        gridTemplateColumns="2fr 1fr"
        height="352px"
        mb={3}
      >
        <ChartContainer
          info={`The data for past ${DEFAULT_PERIOD_DAYS} days`}
          subtitle="The number of all nodes staked"
          title="Nodes Staked - Total"
        >
          <TokenStakedBarChart
            chartDataStaked={systemDailyStatsDataByFields?.stackedNodesCount}
            chartDataUnstaked={systemDailyStatsDataByFields?.unstakedNodesCount}
          />
        </ChartContainer>

        <Box display="grid" gap="24px" gridTemplateRows="1fr 1fr">
          <ChartContainer
            subtitle="The number of all running nodes"
            title="Running nodes"
          >
            <NumberStats color="primary">
              {systemDailyStatsDataset?.totalActiveNodesCount || 0}
            </NumberStats>
          </ChartContainer>

          <ChartContainer
            info={'The comparison with previous week'}
            subtitle="Nodes added/removed weekly"
            title="Nodes ++"
          >
            {renderDiffBlock(
              systemDailyStatsDataset?.nodesWeeklyDiff ?? 0,
              systemDailyStatsDataset?.nodesWeeklyDiffPercentage ?? 0,
            )}
          </ChartContainer>
        </Box>
      </Box>

      <Box height="304px" mb={3}>
        <ChartContainer title="Nodes staked by user count">
          {!systemDailyStatsDataset?.nodeStakedByUserCount?.length ? (
            <EmptyChartPlaceholder />
          ) : (
            <Box display="flex" gap={8}>
              <Box width="184px">
                <NodeStakedByUserChart
                  chartData={systemDailyStatsDataset?.nodeStakedByUserCount}
                  chartType={ChartType.PIE_CHART}
                />
              </Box>
              <Box flex={1}>
                <NodeStakedByUserChart
                  chartData={systemDailyStatsDataset?.nodeStakedByUserCount}
                  chartType={ChartType.COLUMN_CHART}
                />
              </Box>
            </Box>
          )}
        </ChartContainer>
      </Box>

      <Box>
        <Divider />
        <Title my={3}>Validators</Title>
      </Box>
      <Box
        display="grid"
        gap="24px"
        gridTemplateColumns="2fr 1fr"
        height="528px"
        mb={3}
      >
        <ChartContainer
          info={`The data for past ${DEFAULT_PERIOD_DAYS} days`}
          title="Register validator"
        >
          <ValidatorsBarChart
            chartDataClosed={
              systemDailyStatsDataByFields?.closedValidatorsCount
            }
            chartDataRegistered={
              systemDailyStatsDataByFields?.registeredValidatorsCount
            }
          />
        </ChartContainer>

        <Box display="grid" gap="24px" gridTemplateRows="2fr 1fr">
          <ChartContainer
            info={`The data as of ${DateTime.now()
              .minus({ days: 1 })
              .toFormat('MMM dd, yyyy')}`}
            subtitle="Running validators"
            title="Running validators to update"
          >
            <CirculationChart chartData={validatorsCirculationChartData} />
          </ChartContainer>

          <ChartContainer title="Avg. nodes per validator">
            <NumberStats color="primary">
              {systemDailyStatsDataByFields?.avgNodesCountPerValidator?.slice(
                -1,
              )?.[0]?.value ?? 0}
            </NumberStats>
          </ChartContainer>
        </Box>
      </Box>

      <Box>
        <Divider />
        <Title my={3}>MNW Cap</Title>
      </Box>
      <Box
        display="grid"
        gap="24px"
        gridTemplateColumns="2fr 1fr"
        height="408px"
        mb={3}
      >
        <ChartContainer
          info={`The data for past ${DEFAULT_PERIOD_DAYS} days`}
          subtitle="The price of 1 MNW token"
          title="MNW price"
          withoutBottomPadding
        >
          <TokenPriceChart
            chartDataPrice={systemDailyStatsDataByFields?.tokenPrice}
            chartDataTVL={systemDailyStatsDataByFields?.totalLockedAmount}
          />
        </ChartContainer>

        <Box display="grid" gap="24px" gridTemplateRows="5fr 4fr">
          <ChartContainer
            subtitle="Tokens left before the total node cap is depleted"
            title="MNW Cap"
          >
            {renderDiffBlock(
              systemDailyStatsDataset?.stackedTokenAmount,
              calculatePercentage(
                systemDailyStatsDataset?.stackedTokenAmount,
                systemDailyStatsDataset?.maxTrustMNWTokens,
              ),
              true,
            )}
            <BorderLinearProgress
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: diffColors[2],
                },
                my: 1,
              }}
              value={calculatePercentage(
                systemDailyStatsDataset?.stackedTokenAmount,
                systemDailyStatsDataset?.maxTrustMNWTokens,
              )}
              variant="determinate"
            />

            <Typography
              color="rgba(105, 122, 159, 1)"
              fontSize="12px"
              fontWeight={500}
            >
              Left:
              {getFormattedNumber(systemDailyStatsDataset?.tokenCapLeft ?? 0)}(
              {calculatePercentage(
                systemDailyStatsDataset?.tokenCapLeft,
                systemDailyStatsDataset?.maxTrustMNWTokens,
              )}
              % )
            </Typography>
          </ChartContainer>

          <ChartContainer
            subtitle="Total rewards paid up to date"
            title="Rewards paid"
          >
            <NumberStats color="primary">
              {systemDailyStatsDataByFields.rewardsPaid?.slice(-1)?.[0]
                ?.value ?? 0}
            </NumberStats>
          </ChartContainer>
        </Box>
      </Box>

      <Box>
        <Divider />
        <Title my={3}>Validation & Rewards</Title>
      </Box>

      <Box
        display="grid"
        gap="24px"
        gridTemplateColumns="1fr 1fr"
        height="440px"
        mb={3}
      >
        <ChartContainer
          info={`The data for past ${DEFAULT_PERIOD_DAYS} days`}
          subtitle="The charts represent how much validations were successful"
          title="Daily Validations Cap vs Amount of fulfilled validation per day"
        >
          <ValidationsChart
            validationsAmount={systemDailyStatsDataByFields?.validationsAmount}
            validationsCap={systemDailyStatsDataByFields?.validationsCap}
          />
        </ChartContainer>

        <ChartContainer
          info={`The data for past ${DEFAULT_PERIOD_DAYS} days`}
          subtitle="The amount of active nodes with uptime less than 99%"
          title="Below uptime by period"
        >
          <UptimeStatsChart
            belowUptimeAmount={systemDailyStatsDataByFields?.belowUptimeAmount}
            belowUptimeDiffAllPercentage={
              systemDailyStatsDataByFields?.belowUptimeDiffAllPercentage
            }
          />
        </ChartContainer>
      </Box>

      <Box display="grid" height="440px" mb={3}>
        <ChartContainer
          info={`The data for past ${DEFAULT_PERIOD_DAYS} days`}
          subtitle="Validators and Nodes that were unstaked ahead of time"
          title="Participated in early unstake by period"
        >
          <UnstakeStatsChart
            earlyUnstakeNodeAmount={
              systemDailyStatsDataByFields?.earlyUnstakeNodeAmount
            }
            earlyUnstakeValidatorAmount={
              systemDailyStatsDataByFields?.earlyUnstakeValidatorAmount
            }
          />
        </ChartContainer>
      </Box>

      <Box>
        <Divider />
        <Title my={3}>Other</Title>
      </Box>
      <Box
        display="grid"
        gap="24px"
        gridTemplateColumns="1fr 1fr"
        height="476px"
        mb={3}
      >
        <ChartContainer title="Nodes per staking term ">
          <StakingTermsCirculationChart
            chartData={nodesByStakingTermsChartData}
          />
        </ChartContainer>
        <ChartContainer title="Validators per staking term ">
          <StakingTermsCirculationChart
            chartData={validatorsByStakingTermsChartData}
          />
        </ChartContainer>
      </Box>
      <Box
        display="grid"
        gap="24px"
        gridTemplateColumns="1fr 1fr"
        height="476px"
        mb={3}
      >
        <VersionsDonutCharts />
      </Box>
    </Box>
  )
}

export default StatsGraphContainer
