import React, { useCallback, useEffect, useState } from 'react'

import { useMutation } from '@apollo/client'
import {
  Alert as MUIAlert,
  AlertTitle,
  CircularProgress,
  Snackbar,
} from '@mui/material'
import { Box } from '@mui/system'
import { Alert, LoaderHolder } from 'common'
import PageWrapper from 'common/pageWrapper'
import { AlertMessage, Range } from 'common/types'
import { ALERT_DELAY } from 'constants/params'
import { BACKEND_BALANCE } from 'graphql/rewards/mutations/backendBalance'
import { DateTime } from 'luxon'

import RewardTableWrapper from './RewardTableWrapper'
import TotalRewards from './Total'
import { BackendBalance } from './types'

function Rewards() {
  const [balance, setBalance] = useState<BackendBalance>()

  const [range, setRange] = useState<Range>({
    from: DateTime.now().minus({ month: 1 }),
    to: DateTime.now(),
  })

  const [alert, setAlert] = useState<AlertMessage>({
    isOpen: false,
  })

  const [backendBalance, { loading: balanceLoading }] =
    useMutation(BACKEND_BALANCE)

  useEffect(() => {
    backendBalance().then(({ data }) => {
      setBalance(data?.backendBalance)
    })
  }, [backendBalance])

  const handleAlert = useCallback(
    (alertData: AlertMessage) => setAlert(alertData),
    [],
  )

  return (
    <>
      <PageWrapper pageTitle="Rewards">
        {balanceLoading && !balance ? (
          <LoaderHolder sx={{ mx: '32px', my: '16px', height: 36 }}>
            <CircularProgress />
          </LoaderHolder>
        ) : (
          <Box display="flex" gap={2} sx={{ mx: '32px', mb: '16px' }}>
            <MUIAlert icon={false} severity="success" sx={{ width: '50%' }}>
              <AlertTitle>Rewards service wallet</AlertTitle>
              Address : <b>{balance?.mainAddress}</b>
              <br />
              Balance: <b>{balance?.rewardBalance} MNW</b>
              <br />
              Gas balance: <b>{balance?.rewardGasBalance} MATIC</b>
            </MUIAlert>

            <MUIAlert icon={false} severity="info" sx={{ width: '50%' }}>
              <AlertTitle>Validations service wallet</AlertTitle>
              Address: <b>{balance?.validateAddress} </b>
              <br />
              Gas balance: <b>{balance?.validateGasBalance} MATIC</b>
              <br />
            </MUIAlert>
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          sx={{ mb: '10px', mx: '30px' }}
        >
          <Box>
            <TotalRewards range={range} onChangeDateRange={setRange} />
            <RewardTableWrapper range={range} onAlert={handleAlert} />
          </Box>
        </Box>
      </PageWrapper>
      <Snackbar
        autoHideDuration={ALERT_DELAY}
        open={alert?.isOpen}
        onClose={() => setAlert({ isOpen: false })}
      >
        <Alert
          severity={alert?.alertColor}
          sx={{ width: '100%' }}
          onClose={() => setAlert({ isOpen: false })}
        >
          {alert?.text}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Rewards
